export default {
    state: {
        cart: []
    },
    getters: {
        cartItems: state => state.cart
    },

    mutations: {
        setCart (state, payload) {
            state.cart = payload;
        }
    },

    actions: {
        setCart ({ commit }, payload) {
            commit('setCart', payload);
        }
    }
};
