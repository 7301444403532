import Vue from 'vue';
import VueRouter from 'vue-router';
import products from './products';
import auth from './auth';
import appRoutes from './app-layout';

Vue.use(VueRouter);

const routes = [
    auth,
    products,
    appRoutes
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

router.beforeResolve((to, from, next) => {
    if (to.name) {
    }
    next();
});

router.afterEach(() => {
});

export default router;
